import { Image, Container, Text, Anchor, Center, Group } from '@mantine/core';
import { IconMail, IconBrandWhatsapp } from '@tabler/icons-react';
import gif from '../../public/empresas_highres.gif';

export default function Landing() {
  return (
    <Container
      fluid
      style={{
        height: '100vh',
        maxHeight: '100vh',
        backgroundColor: '#F5B834',
      }}
    >
      <Container
        style={{
          height: '100vh',
          backgroundColor: '#F5B834',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Center>
          <Image
            src={gif}
            alt="animationbussiness"
            style={{
              maxWidth: '100%',
              marginBottom: '0px',
            }}
          />
        </Center>
        <Text
          sx={(theme) => ({
            fontSize: theme.breakpoints.xs <= 768 ? '36px' : '36px', // Ajusta según tus breakpoints preferidos
            fontWeight: 800,
            textAlign: 'center',
            color: 'black',
            maxWidth: '80%',
            lineHeight: 1,
          })}
        >
          ...y un dia cambiamos de look!
        </Text>{' '}
        <Text
          sx={(theme) => ({
            fontSize: theme.breakpoints.xs <= 768 ? '24px' : '18px',
            lineHeight: 1.5,
            fontWeight: 200,
            textAlign: 'center',
            color: 'black',
            maxWidth: '80%',
            marginTop: 10,
            marginBottom: 10,
          })}
        >
          Se vienen muchas cosas nuevas en{' '}
          <span
            style={{
              fontWeight: 500,
              color: '#1DAEA5',
              backgroundColor: 'whitesmoke',
              borderTopLeftRadius: 10,
              borderBottomRightRadius: 10,
              padding: 5,
            }}
          >
            SWITCH IT
          </span>{' '}
          y queremos compartirlas con ustedes!
        </Text>
        <Text
          ta={'center'}
          pt={10}
          sx={(theme) => ({
            fontSize: theme.breakpoints.xs <= 768 ? '14px' : '10px', // Ajusta según tus breakpoints preferidos
            lineHeight: 1,
            fontWeight: 600,
            textAlign: 'center',
            color: 'black',
            maxWidth: '80%',
          })}
        >
          {' '}
          Mientras tanto, podes contactarnos via mail o por whatsapp{' '}
        </Text>
        <Group position="center" spacing="xl" style={{ marginTop: '20px' }}>
          <Anchor
            href="mailto:info@switchit.com.ar"
            color="dark"
            underline={false}
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'false',
            }}
          >
            <IconMail />
            info@switchit.com.ar
          </Anchor>
          <Anchor
            href="https://wa.me/1167131996"
            underline={false}
            color="dark"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconBrandWhatsapp />
            1167131996
          </Anchor>
        </Group>
      </Container>
    </Container>
  );
}
